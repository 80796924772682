// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inspirations-index-tsx": () => import("./../../../src/pages/inspirations/index.tsx" /* webpackChunkName: "component---src-pages-inspirations-index-tsx" */),
  "component---src-pages-planning-index-tsx": () => import("./../../../src/pages/planning/index.tsx" /* webpackChunkName: "component---src-pages-planning-index-tsx" */),
  "component---src-pages-resources-index-tsx": () => import("./../../../src/pages/resources/index.tsx" /* webpackChunkName: "component---src-pages-resources-index-tsx" */),
  "component---src-pages-share-index-tsx": () => import("./../../../src/pages/share/index.tsx" /* webpackChunkName: "component---src-pages-share-index-tsx" */),
  "component---src-pages-share-plan-index-tsx": () => import("./../../../src/pages/share-plan/index.tsx" /* webpackChunkName: "component---src-pages-share-plan-index-tsx" */),
  "component---src-pages-stories-of-abdul-baha-index-tsx": () => import("./../../../src/pages/stories-of-abdul-baha/index.tsx" /* webpackChunkName: "component---src-pages-stories-of-abdul-baha-index-tsx" */),
  "component---src-pages-submit-resource-index-tsx": () => import("./../../../src/pages/submit-resource/index.tsx" /* webpackChunkName: "component---src-pages-submit-resource-index-tsx" */),
  "component---src-pages-view-resources-index-tsx": () => import("./../../../src/pages/view-resources/index.tsx" /* webpackChunkName: "component---src-pages-view-resources-index-tsx" */),
  "component---src-templates-inspiration-template-tsx": () => import("./../../../src/templates/InspirationTemplate.tsx" /* webpackChunkName: "component---src-templates-inspiration-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/PageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-resource-template-tsx": () => import("./../../../src/templates/ResourceTemplate.tsx" /* webpackChunkName: "component---src-templates-resource-template-tsx" */),
  "component---src-templates-story-template-tsx": () => import("./../../../src/templates/StoryTemplate.tsx" /* webpackChunkName: "component---src-templates-story-template-tsx" */)
}

