// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from "@chakra-ui/react";
const theme = {
  colors: {
    brand: "#444B3C",
    delta: "#B1B1AC",
    zorba: "#9D9691",
    sage: "#A3A58F",
  },
  styles: {
    global: {
      "::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
      },
      "::-webkit-scrollbar-track": {
        background: "rgba(0, 0, 0, 0)",
      },
      "::-webkit-scrollbar-thumb": {
        background: "rgba(0, 0, 0, 0.2)",
        borderRadius: "3px",
      },
    },
  },
};

export default extendTheme(theme);
